.dropzone-react {
    position: relative;
    height: 200px;
    width: 300px;
    margin-left: 50%;
    transform: translate(-50%);
    border: 2px solid $secondary;
}

.container-dropzone-react {
    position: relative;
}

.fa-dropzone-react {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    color: $secondary;
}
