// Global styling for this template

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

body.sticky-footer {
  margin-bottom: $sticky-footer-height;
  .content-wrapper {
    min-height: calc(100vh - #{$sticky-footer-height} - #{$navbar-base-height});
  }
}

body.fixed-nav {
  padding-top: $navbar-base-height;
}

.content-wrapper {
  min-height: calc(100vh - #{$sticky-footer-height});
  padding-top: 1rem;
}

h1, h2{
  display: block;
  margin-bottom: 5px;
  margin-top: 6px;
  font-weight: 400!important;
  letter-spacing: -1px;
  font-size: 25px!important;
  color: #4e5155;
  line-height: 36px;
  text-transform: uppercase;
}
span{
  font-size:0.9rem;
}
.form-control{
  font-size:13px;
}
.badge{
  padding: 5px 10px;
}
// Scroll to top button
.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 3px;

  display: none;

  width: 50px;
  height: 50px;

  text-align: center;

  color: white;
  background: fade-out($gray-800, .5);

  line-height: 45px;
  &:focus,
  &:hover {
    color: white;
  }
  &:hover {
    background: $gray-800;
  }
  i {
    font-weight: 800;
  }
}
