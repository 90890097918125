.btn-default{
  @include button-outline-variant($secondary);
}

.btn-default.bootbox-cancel{
  @include button-variant($secondary, $secondary);
}

.btn-move{
  @extend %fa-icon;
  @extend .fas;

  &:before {
    content: fa-content($fa-var-arrows-alt);
  }
}

.btn{
  line-height: 1.2;
}
.btn-log{
  background-color: #99cc33;
  color:#fff;
  border-radius: 0;
  border:transparent;
}
.btn-log:hover{
  background-color: #000;
  border:transparent;
}