//Config variables
@import "includes/variables.scss";

// NPM BOOTSTRAP
@import "~bootstrap/scss/bootstrap.scss";

// NPM FONT AWESOME V5
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";

//Mixins
@import "includes/mixins/background-variant.scss";
@import "includes/mixins/font.scss";

//General style
@import "includes/general.scss";

//Structure
@import "includes/structure/navbar/navbar_global.scss";
@import "includes/structure/navbar/navbar_fixed.scss";
@import "includes/structure/navbar/navbar_static.scss";
@import "includes/structure/navbar/navbar_toggle.scss";
@import "includes/structure/navbar/navbar_colors.scss";

@import "includes/structure/footer.scss";
@import "includes/structure/page-actions.scss";

//Fonts
@import "includes/fonts/roboto.scss";


// Elements
@import "includes/elements/buttons.scss";
@import "includes/elements/cards.scss";
@import "includes/elements/colors.scss";
@import "includes/elements/modal.scss";
@import "includes/elements/multiselect.scss";
@import "includes/elements/dropzone.scss";

// Forms
@import "includes/forms/forms.scss";

// Specific pages style
@import "includes/pages/login.scss";


@import "includes/plugins/devbridge-autocomplete.scss";


// Modules
@import "includes/modules/cms/cms.scss";
@import "includes/modules/cms/seo.scss";
@import "includes/modules/platform/platform.scss";
@import "includes/modules/user/roles.scss";
